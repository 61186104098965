import '../css/app.css'
import './bootstrap'

import { createInertiaApp } from '@inertiajs/react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { hydrateRoot } from 'react-dom/client'

const appName = import.meta.env.VITE_APP_NAME || 'Fynbos'

createInertiaApp({
  title: (title) => `${title || appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx')
    ),
  setup({ el, App, props }) {
    hydrateRoot(el, <App {...props} />)
  },
  progress: {
    color: '#F43F5E'
  }
})
